import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import { Wave1 } from '../components/wave';
import WaveImg from './../assets/images/wave.png';
import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Blog from '../components/blog';
import CTAComponent from '../components/cta';
import Search from '../components/search';
import Pagination from '../components/pagination';
import Title from '../components/typography/Title';

const Background = styled.div`
    background-color: transparent;
    background: url(${WaveImg}) no-repeat contain;
    background-position: top 317px;
    margin-bottom: 112px;
    @media screen and (max-width: 767px) {
        margin-bottom: 129px;
    }
`;
const Heading = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    padding-top: 149px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        margin-left: 32px;
        margin-right: 32px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 108px;
    }
`;
const WaveSection = styled.div`
    position: absolute;
    width: 100%;
    margin-top: -30px;
`;
const BlogContent = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 48px;
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 32px;
        margin-bottom: 40px;
    }
`;

/**
 * Component for the content-library list page.
 *
 * @component
 * @param {Object} data - data fetched from the CMS's content-library page
 */
export default function ContentLibraryPage({ data }) {
    const { hero, contents, seo_content } = data.butterPage;
    const [filterBlogs, setFilterBlogs] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [blogsPerPage] = useState(9);
    const [currentPage, setCurrentPage] = useState(0);
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const handlePage = page => {
        setCurrentPage(page);
    };
    useEffect(() => {
        let array = contents.filter(blog => {
            return (
                blog.title.toLowerCase().includes(searchText.toLowerCase()) ||
                blog.summary.toLowerCase().includes(searchText.toLowerCase())
            );
        });
        setFilterBlogs(array);
        return () => {};
    }, [searchText, contents]);

    useEffect(() => {
        setFilterBlogs(contents);
        return () => {};
    }, [contents]);

    return (
        <Layout menu="INSIGHTS">
            <SEOComponent
                title="Content Library"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <Title>{hero[0].title}</Title>
                    <Search
                        value={searchText}
                        onTextChange={text => setSearchText(text)}
                    />
                </Heading>
                <WaveSection>
                    <Wave1 />
                </WaveSection>
                <BlogContent>
                    {filterBlogs.map((blog, index) => {
                        return currentPage * blogsPerPage <= index &&
                            index < filterBlogs.length &&
                            index < (currentPage + 1) * blogsPerPage ? (
                            <Blog
                                key={index}
                                page_slug="content-library"
                                {...blog}
                            />
                        ) : null;
                    })}
                </BlogContent>
                <Pagination
                    currentPage={currentPage}
                    totalPages={
                        Math.floor(filterBlogs.length / blogsPerPage) + 1
                    }
                    onNext={() => handleNextPage()}
                    onPrev={() => handlePrevPage()}
                    onPage={page => handlePage(page)}
                />
            </Background>
            <CTAComponent />
        </Layout>
    );
}

/* GraphQL query*/
export const contentLibraryPageQuery = graphql`
    query {
        butterPage(slug: { eq: "content-library" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
            }
            contents {
                title
                summary
                body
                publish_date
                category
                featured_image
                download_url
                url_slug
            }
        }
    }
`;
